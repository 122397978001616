import {Burger, Group, Header, MediaQuery, Select, Title} from "@mantine/core";
import UserButton from "../userButton/userButton";
import Link from "next/link";
import {useApi} from "../../context/api.context";
import Image from "next/image";
import {useTheme} from "../../context/theme.context";
import {Role} from "../../models/roles.enum";

interface IAppHeaderProps {
    opened: boolean;

    setOpened(open: boolean): void;
}

export default function AppHeader(props: IAppHeaderProps) {
    const theme = useTheme();
    const api = useApi();

    return (
        <Header
            height={60}
            px={"md"}
        >
            <Group position={"apart"} h={"100%"}>
                <MediaQuery largerThan={"sm"} styles={{display: 'none'}}>
                    <Burger
                        opened={props.opened}
                        onClick={() => props.setOpened(!props.opened)}
                        size={"sm"}
                        mr={"xl"}
                    />
                </MediaQuery>

                <MediaQuery smallerThan={"sm"} styles={{display: "none"}}>
                    <Link href={"/"}>
                        <Image
                            src={theme.isDark ? "/logo-dark.png" : "/logo-light.png"}
                            alt={"logo"}
                            height={40}
                            width={160}
                        />
                    </Link>
                </MediaQuery>

                <Group>
                    {
                        (api.user?.roleId === Role.ReportingUser || api.companies.length > 1)
                            ? <Select
                                data={api.companies?.map(i => ({value: i.id.toString(), label: i.name})) || []}
                                value={api.company?.id.toString() || ""}
                                onChange={(value) => {
                                    const company = api.companies.find(i => i.id === (value ? +value : 0));
                                    if (company) api.changeCompany(company);
                                }}
                            />
                            : <Title size={"h3"}>{api.company?.name}</Title>
                    }
                    <UserButton/>
                </Group>

            </Group>
        </Header>
    );
}