import {
	Divider,
	Drawer,
	MediaQuery,
	Navbar,
	NavLink,
} from "@mantine/core";
import Link from "next/link";
import { useRouter } from "next/router";
import {
	TbBuilding,
	TbChartBar,
	TbClipboard,
	TbHelp,
	TbHome,
	TbNetwork,
	TbSettings,
	TbUser,
	TbUsers,
	TbWorld,
} from "react-icons/tb";
import Image from "next/image";
import { useTheme } from "../../context/theme.context";
import { PermissionCheck } from "../permissionCheck/permissionCheck";

interface INavBarProps {
	opened: boolean;

	setOpened(open: boolean): void;
}

export default function NavBar(props: INavBarProps) {
	const theme = useTheme();
	const router = useRouter();

	function Content() {
		return (
			<>
				<MediaQuery
					largerThan={"sm"}
					styles={{ display: "none" }}
				>
					<div>
						<Navbar.Section>
							<Link href={"/"}>
								<Image
									src={theme.isDark ? "/logo-dark.png" : "/logo-light.png"}
									alt={"logo"}
									height={40}
									width={160}
								/>
							</Link>
						</Navbar.Section>
						<Divider my={"sm"}/>
					</div>
				</MediaQuery>

				<Navbar.Section grow>
					<PermissionCheck
						permission={"isCheck5Admin"}
					>
						<NavLink
							component={Link}
							href={"/check5admin"}
							label={"Check5 Dashboard"}
							active={router.asPath === "/check5admin"}
							icon={<TbBuilding size={24}/>}
							color={"cyan"}
						/>
					</PermissionCheck>
					<NavLink
						component={Link}
						href={"/"}
						label={"Company Dashboard"}
						active={router.asPath === "/"}
						icon={<TbHome size={24}/>}
						color={"cyan"}
					/>

					<Divider my={"sm"}/>

					<PermissionCheck permission={"canReadSurveys"}>
						<NavLink
							component={Link}
							href={"/surveys"}
							label={"All Surveys"}
							active={router.asPath.startsWith("/surveys")}
							icon={<TbClipboard size={24}/>}
							color={"violet"}
						/>
					</PermissionCheck>
					<PermissionCheck permission={"canReadEmployeeData"}>
						<NavLink
							component={Link}
							href={"/teams"}
							label={"Teams"}
							active={router.asPath.startsWith("/teams")}
							icon={<TbUsers size={24}/>}
							color={"green"}
						/>
					</PermissionCheck>
					<PermissionCheck permission={"canReadEmployeeData"}>
						<NavLink
							component={Link}
							href={"/employees"}
							label={"Employees"}
							active={router.asPath.startsWith("/employees")}
							icon={<TbUser size={24}/>}
							color={"blue"}
						/>
					</PermissionCheck>
					<PermissionCheck permission={"canReadSurveys"}>
						<NavLink
							component={Link}
							href={"/networkPartners"}
							label={"Network Partners"}
							active={router.asPath.startsWith("/networkPartners")}
							icon={<TbNetwork size={24}/>}
							color={"yellow"}
						/>
					</PermissionCheck>
					<PermissionCheck
						permission={"showReportAsDashboard"}
						showOnCheckFail
					>
						<NavLink
							component={Link}
							href={"/reports"}
							label={"Reports"}
							active={router.asPath.startsWith("/reports")}
							icon={<TbChartBar size={24}/>}
							color={"red"}
						/>
					</PermissionCheck>
				</Navbar.Section>
				<Navbar.Section>
					<NavLink
						component={Link}
						href={"/help"}
						label={"Help"}
						active={router.asPath.startsWith("/help")}
						icon={<TbHelp size={24}/>}
						color={"cyan"}
					/>
					<PermissionCheck permission={"canManageGlobalNetworkPartners"}>
						<NavLink
							component={Link}
							href={"/globalNetworkPartners"}
							label={"Network Partners (G)"}
							active={router.asPath.startsWith("/globalNetworkPartners")}
							icon={<TbWorld size={24}/>}
							color={"cyan"}
						/>
					</PermissionCheck>
					<PermissionCheck permission={"canReadCompany"}>
						<Divider my={"sm"}/>
						<NavLink
							component={Link}
							href={"/company"}
							label={"Settings"}
							active={router.asPath.startsWith("/company")}
							icon={<TbSettings size={24}/>}
							color={"orange"}
						/>
					</PermissionCheck>
				</Navbar.Section>
			</>
		);
	}

	return (
		<>
			<MediaQuery
				smallerThan={"sm"}
				styles={{ display: "none" }}
			>
				<Navbar
					p={"md"}
					width={{ base: 250 }}
				>
					<Content/>
				</Navbar>
			</MediaQuery>
			<MediaQuery
				largerThan={"sm"}
				styles={{ display: "none" }}
			>
				<Drawer
					opened={props.opened}
					onClose={() => props.setOpened(false)}
					size={250}
					overlayProps={{
						opacity: 0.5,
						blur: 4,
					}}
					withCloseButton={false}
					styles={{
						body: {
							height: "100vh",
							display: "flex",
							flexDirection: "column",
						},
					}}
				>
					<Content/>
				</Drawer>
			</MediaQuery>
		</>
	);
}